import { useState, useEffect } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { Citation, Message as MessageType } from "@/lib/api/types";
import { useStore } from "@/state/useState";
import Markdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  ThumbsUp,
  ThumbsDown,
  Clipboard,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { parseISO, format, formatDistanceToNow } from "date-fns";
import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";

function MessageContent({
  text,
  // image,
  citations,
  created_at,
  getCitation,
}: MessageType["content"] & {
  created_at: string;
  getCitation: (citation: Citation) => void;
}) {
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (text === "") {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 3 ? "" : prevDots + "."));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [text]);

  const formatMessageDate = (dateString: string) => {
    const date = parseISO(dateString);
    const formattedDate = format(date, "MMM d 'at' h:mm a");
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });
    return `${formattedDate} (${timeAgo})`;
  };
  const components: Partial<Components> = {
    a: (props) => {
      const { href, children } = props;
      const match = href?.match(/^citation-(\d+)$/);
      if (!match)
        return (
          <a
            href={href}
            className="text-blue-500 hover:underline"
            target="_blank"
          >
            {children}
          </a>
        );
      const citation = citations?.[parseInt(match[1]) - 1];
      if (!citation) return null;
      return (
        <Button
          variant="link"
          className="p-0 h-auto"
          onClick={() => getCitation(citation)}
        >
          [{match[1]}]
        </Button>
      );
    },
  };

  const processedText = text.replace(
    /\[doc(\d+)\]/g,
    (match: string, p1: string) => `[${match}](citation-${p1})`
  );

  // return processedText;
  return (
    <div>
      <Markdown remarkPlugins={[remarkGfm]} components={components}>
        {processedText !== "" ? processedText : `Generating response ${dots}`}
      </Markdown>

      <span className="text-xs text-muted-foreground mt-1 block">
        {formatMessageDate(created_at)}
      </span>
    </div>
  );
}

export default function Message({
  content,
  created_at,
  id,
  role,
  feedback,
}: MessageType) {
  const documents = useStore((state) => state.documents);
  const loadDocuments = useStore((state) => state.loadDocuments);
  const loadDocument = useStore((state) => state.loadDocument);

  const handleDownload = async (documentId: string) => {
    const doc = await loadDocument(documentId);
    if (doc.type === "sharepoint") {
      window.open(doc.download_url ?? "", "_blank");
      return;
    } else if (doc.type === "url") {
      window.open(doc.origin, "_blank");
      return;
    }

    await loadDocuments();
    console.log("Download", documentId);
    try {
      const response = await fetchWithAuth(
        urlBuilder(`/doc/download/${documentId}`)
      );
      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;

      // Get the document from our current documents state
      const documentData = documents.find((doc) => doc.id === documentId);
      const filename = documentData
        ? documentData.origin
        : `document-${documentId}`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading document:", error);
      alert("Error downloading document.");
    }
  };
  const getCitation = (citation: Citation) => {
    console.log(citation);
    // getDocument
    return handleDownload(citation.document_id);
  };

  const isUser = role === "user";
  const [citationsOpen, setCitationsOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackOptions, setFeedbackOptions] = useState<string[]>([]);
  const [copied, setCopied] = useState(false);
  const submitFeedback = useStore((state) => state.submitFeedback);

  const feedbackTypes = {
    missing_citation: "Citations are missing",
    wrong_citation: "Citations are incorrect",
    out_of_scope: "The response is not from my data",
    other_unhelpful: "Other (unhelpful)",
    inaccurate_or_irrelevant: "Inaccurate or irrelevant information",
  };

  useEffect(() => {
    if (feedback) {
      const feedbackArray = feedback.split(", ");
      setFeedbackOptions(feedbackArray);
    }
  }, [feedback, content.text]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content.text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className={`flex ${isUser ? "justify-end" : "justify-start"} mb-4`}>
      <div
        className={`flex ${
          isUser ? "flex-row-reverse" : "flex-row"
        } items-start w-3/4`}
      >
        <Avatar className="w-8 h-8 mr-2">
          {isUser ? (
            <>
              <AvatarImage src={"/user-avatar.png"} />
              <AvatarFallback>U</AvatarFallback>
            </>
          ) : (
            <>
              <AvatarImage src={"/ai-avatar.png"} />
              <AvatarFallback>AI</AvatarFallback>
            </>
          )}
        </Avatar>
        <Card
          className={`${
            isUser ? "bg-primary text-primary-foreground w-full" : "w-full"
          }`}
        >
          <CardContent className="p-3">
            <MessageContent {...{ ...content, created_at, getCitation }} />
          </CardContent>
          {!isUser && (
            <CardFooter
              className={`flex flex-col items-start space-y-2 p-3 pt-0 ${
                content.citations ? "pb-2" : ""
              }`}
            >
              <div className="flex space-x-2">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant={
                          feedback === "positive" ? "secondary" : "ghost"
                        }
                        size="icon-small"
                        onClick={() => submitFeedback(id, "positive")}
                      >
                        <ThumbsUp className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Mark as helpful</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>

                <Dialog open={feedbackOpen} onOpenChange={setFeedbackOpen}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                          <Button
                            variant={
                              feedback && feedback !== "positive"
                                ? "secondary"
                                : "ghost"
                            }
                            size="icon-small"
                          >
                            <ThumbsDown className="h-4 w-4" />
                          </Button>
                        </DialogTrigger>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Mark as unhelpful</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Feedback</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                      <p className="text-sm text-muted-foreground">
                        Jouw feedback zal deze ervaring verbeteren.
                      </p>
                      <p>Wat was er mis met deze reactie?</p>
                      {Object.entries(feedbackTypes).map(([key, value]) => (
                        <div key={key} className="flex items-center space-x-2">
                          <Checkbox
                            id={key}
                            checked={feedbackOptions.includes(key)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                setFeedbackOptions([...feedbackOptions, key]);
                              } else {
                                setFeedbackOptions(
                                  feedbackOptions.filter((item) => item !== key)
                                );
                              }
                            }}
                          />
                          <Label htmlFor={key}>{value}</Label>
                        </div>
                      ))}
                      <Button
                        onClick={() => {
                          submitFeedback(id, feedbackOptions.join(", "));
                          setFeedbackOpen(false);
                        }}
                      >
                        Verstuur feedback
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon-small"
                        onClick={handleCopy}
                      >
                        <Clipboard className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{copied ? "Copied!" : "Copy to clipboard"}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              {content.citations && content.citations.length > 0 && (
                <Collapsible
                  open={citationsOpen}
                  onOpenChange={setCitationsOpen}
                >
                  <CollapsibleTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="flex justify-between"
                    >
                      <span>Bronnen: {content.citations.length}</span>
                      {citationsOpen ? (
                        <ChevronUp className="h-4 w-4" />
                      ) : (
                        <ChevronDown className="h-4 w-4" />
                      )}
                    </Button>
                  </CollapsibleTrigger>
                  <CollapsibleContent className="space-y-2 mb-4 flex flex-col w-1/2">
                    {content.citations.map((citation, index) => (
                      <Button
                        key={index}
                        variant="link"
                        size="sm"
                        className="h-auto text-xs justify-start"
                        // onClick={() => {openWindow}}
                        onClick={() => {
                          console.log({ citation });
                          getCitation(citation);
                        }}
                      >
                        <span className="flex items-center gap-1 w-full">
                          <span className="shrink-0">[{index + 1}] </span>
                          <span className="flex-1" title={citation.title}>
                            {"..." + citation.title.slice(-70)}
                          </span>
                        </span>
                      </Button>
                    ))}
                  </CollapsibleContent>
                </Collapsible>
              )}
            </CardFooter>
          )}
        </Card>
      </div>
    </div>
  );
}
