import React, { useEffect } from "react";
import { useStore } from "@/state/useState";
import { isToday, isYesterday, subDays } from "date-fns";
import { Pencil, Trash2, MessageSquare } from "lucide-react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { HistoryItem } from "@/lib/api/types";
import { motion } from "framer-motion";
import { useState } from "react";

function HistoryGroup({
  title,
  items,
  loadChatHistory,
  deleteHistoryItem,
  clearCitation,
  sessionId,
}: {
  title: string;
  items: HistoryItem[];
  loadChatHistory: (sessionId: string) => void;
  deleteHistoryItem: (sessionId: string) => void;
  clearCitation: () => void;
  sessionId: string | null;
}) {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  return (
    <>
      <h3 className="mb-2 px-2 text-sm font-medium text-muted-foreground">
        {title}
      </h3>
      {items.map((item: HistoryItem) => (
        <div
          key={item.id}
          className={`relative overflow-hidden rounded-md my-2 ${
            sessionId === item.id ? "bg-accent" : ""
          }`}
        >
          <motion.div
            className="flex items-center"
            animate={{ x: hoveredItem === item.id ? -40 : 0 }}
          >
            <Button
              variant="ghost"
              className={`h-auto w-full justify-start p-2 text-sm ${
                sessionId === item.id ? "bg-accent" : ""
              }`}
              onClick={() => {
                clearCitation();
                loadChatHistory(item.id);
              }}
            >
              <MessageSquare className="mr-2 h-4 w-4" />
              <span className="truncate">{item.title}</span>
            </Button>
          </motion.div>
          <motion.div
            className="absolute top-0 right-0 h-full w-10 bg-red-500 flex items-center justify-center cursor-pointer"
            initial={{ opacity: 0 }}
            animate={{ opacity: hoveredItem === item.id ? 1 : 0 }}
            onMouseEnter={() => setHoveredItem(item.id)}
            onMouseLeave={() => setHoveredItem(null)}
            onClick={(e) => {
              e.stopPropagation();
              deleteHistoryItem(item.id);
            }}
          >
            <Trash2 className="text-white h-4 w-4" />
          </motion.div>
        </div>
      ))}
    </>
  );
}

function SidebarChatHistory() {
  const history = useStore((state) => state.history);
  const sessionId = useStore((state) => state.sessionId);
  const loadChatHistory = useStore((state) => state.loadChatHistory);
  const deleteHistoryItem = useStore((state) => state.deleteHistoryItem);
  const clearCitation = useStore((state) => state.clearCitation);
  const config = useStore((state) => state.config);

  if ((!history || history.length === 0) && !config?.chat_history) {
    return <CardContent>No chat history yet</CardContent>;
  }

  const now = new Date();
  const groupedHistory = history.reduce(
    (acc, item) => {
      const itemDate = new Date(item.updated_at);
      if (isToday(itemDate)) acc.today.push(item);
      else if (isYesterday(itemDate)) acc.yesterday.push(item);
      else if (itemDate > subDays(now, 7)) acc.last7Days.push(item);
      else if (itemDate > subDays(now, 30)) acc.last30Days.push(item);
      else acc.older.push(item);
      return acc;
    },
    { today: [], yesterday: [], last7Days: [], last30Days: [], older: [] } as {
      [key: string]: HistoryItem[];
    }
  );

  const historyGroups = [
    { title: "Vandaag", items: groupedHistory.today },
    { title: "Gisteren", items: groupedHistory.yesterday },
    { title: "Laatste 7 dagen", items: groupedHistory.last7Days },
    { title: "Laatste 30 dagen", items: groupedHistory.last30Days },
    { title: "Oudere chats", items: groupedHistory.older },
  ];

  return (
    <ScrollArea className="flex-grow px-1">
      {historyGroups.map(
        (group, i) =>
          group.items.length > 0 && (
            <React.Fragment key={group.title}>
              {i > 0 && <Separator className="my-2" />}
              <HistoryGroup
                title={group.title}
                items={group.items}
                loadChatHistory={loadChatHistory}
                deleteHistoryItem={deleteHistoryItem}
                clearCitation={clearCitation}
                sessionId={sessionId}
              />
            </React.Fragment>
          )
      )}
    </ScrollArea>
  );
}

export default function ChatHistory() {
  const config = useStore((state) => state.config);
  const fetchSessionList = useStore((state) => state.fetchSessionList);
  const clearChat = useStore((state) => state.clearChat);

  useEffect(() => {
    fetchSessionList();
  }, [fetchSessionList]);

  return (
    <Card className="flex flex-col h-full rounded-xl bg-card overflow-hidden">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-xl">Chat Geschiedenis</CardTitle>
        <Button variant="ghost" size="icon-small" onClick={clearChat}>
          <Pencil className="h-4 w-4" />
        </Button>
      </CardHeader>
      <CardContent className="flex-grow overflow-y-auto p-0">
        {config?.chat_history ? (
          <SidebarChatHistory />
        ) : config?.chat_history === false ? (
          <div className="p-4 text-muted-foreground">
            Chat history is disabled
          </div>
        ) : (
          <div className="flex p-4 w-full justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
