import { useStore } from "@/state/useState";
import { useEffect } from "react";
import FileStatsTable from "@/components/filepage/FileStatsTable";
import FileUpload from "@/components/filepage/FileUpload";
import AppLayout from "@/components/AppLayout";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function FilesPage() {
  const loadDocuments = useStore((state) => state.loadDocuments);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  return (
    <AppLayout>
      <Card className="flex flex-col h-full rounded-xl bg-card overflow-hidden col-span-2">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="text-xl">Documenten</CardTitle>
        </CardHeader>
        <CardContent className="flex-grow overflow-y-auto p-0">
          <FileStatsTable />
        </CardContent>
      </Card>
      <FileUpload />
    </AppLayout>
  );
}
