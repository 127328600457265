import { fetchWithAuth } from "@/lib/api/utils";
import { useStore } from "@/state/useState";
import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { X } from "lucide-react";
import { formatFileSize } from "@/lib/utils";
import { urlBuilder } from "@/lib/api/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

const FileUpload = () => {
  const loadDocuments = useStore((state) => state.loadDocuments);
  const documents = useStore((state) => state.documents);

  const [files, setFiles] = useState<File[]>([]);
  const [urls, setUrls] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogContent, setAlertDialogContent] = useState({
    title: "",
    description: "",
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      setFiles(Array.from(uploadedFiles));
    }
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUrls(event.target.value);
  };

  const totalSize = documents.reduce(
    (acc, document) => acc + document.data.contentLength,
    0
  );
  const totalChunks = documents.reduce(
    (acc, document) => acc + document.data.chunkCount,
    0
  );

  const showAlert = (title: string, description: string) => {
    setAlertDialogContent({ title, description });
    setAlertDialogOpen(true);
  };

  const handleUpload = async () => {
    if (files.length === 0 && urls.trim() === "") {
      showAlert(
        "Geen bestanden of URLs",
        "Selecteer ten minste één bestand of voer URLs in!"
      );
      return;
    }
    setIsUploading(true);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("urls", urls);

    try {
      const response = await fetchWithAuth(urlBuilder("/document"), {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        setFiles([]);
        setUrls("");
        showAlert(
          "Upload succesvol",
          "De bestanden en URLs zijn succesvol geüpload."
        );
      } else {
        if (response.status === 400) {
          showAlert(
            "Bad Request",
            "The server couldn't process your request. Please check your input and try again."
          );
        }
        if (response.status === 409) {
          showAlert(
            "Bestand al bestaat",
            "Een of meerdere bestanden die je probeert te uploaden, bestaan al in het systeem."
          );
        }
      }
    } catch (error) {
      console.error("Error uploading:", error);
      showAlert(
        "Upload Error",
        "An error occurred while uploading. Please try again later."
      );
    } finally {
      setIsUploading(false);
      loadDocuments();
    }
  };

  const removeSelectedFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="col-span-1">
      <Card>
        <CardHeader>
          <CardTitle>Voeg data toe aan het systeem</CardTitle>
        </CardHeader>
        <CardContent>
          {isUploading ? (
            <div className="flex flex-col items-center">
              <div className="flex p-4 w-full justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
              </div>
              <p className="text-sm text-muted-foreground mt-4">
                Uploaden... dit kan even duren.
              </p>
            </div>
          ) : (
            <>
              <p className="text-sm text-muted-foreground mb-4">
                Upload PDF documenten of voer URLs in om te indexeren.
              </p>
              <div className="flex items-start mb-4">
                <Input
                  type="file"
                  id="file-input"
                  onChange={handleFileChange}
                  className="hidden"
                  multiple={true}
                  accept="application/pdf"
                />
                <Button asChild className="mr-4">
                  <label htmlFor="file-input">Kies bestand</label>
                </Button>

                {files.length > 0 && (
                  <div className="flex flex-col">
                    {files.map((file, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <p className="text-sm text-muted-foreground mr-2">
                          {file.name}
                        </p>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeSelectedFile(index)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <Textarea
                value={urls}
                onChange={handleUrlChange}
                placeholder="Voer komma-gescheiden URLs in:&#10;http://voorbeeld.nl, http://voorbeeld2.com"
                className="mb-4"
                rows={3}
              />

              <Button onClick={handleUpload}>Upload</Button>
            </>
          )}
        </CardContent>
      </Card>
      <Card className="my-4">
        <CardHeader>
          <CardTitle>Documenten Samenvatting</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Aantal documenten
              </p>
              <p className="text-2xl font-semibold">{documents.length}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Aantal Chunks</p>
              <p className="text-2xl font-semibold">{totalChunks}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Total Grootte</p>
              <p className="text-2xl font-semibold">
                {formatFileSize(totalSize)}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      <AlertDialog open={alertDialogOpen} onOpenChange={setAlertDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{alertDialogContent.title}</AlertDialogTitle>
            <AlertDialogDescription>
              {alertDialogContent.description}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setAlertDialogOpen(false)}>
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default FileUpload;
