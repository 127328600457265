import { StateCreator } from "zustand";
import { Store } from "./types";
import { fetchDocuments as fetchDocumentsAPI, getDocument } from "@/lib/api/document";
import { toast } from "@/hooks/use-toast";

export const createDocumentSlice: StateCreator<
  Store,
  [],
  [],
  Pick<
    Store,
    | "documents"
    | "loadDocument"
    | "loadDocuments"
    | "startDocumentPolling"
    | "stopDocumentPolling"
  >
> = (set, get) => ({
  documents: [],
  loadDocument: async (documentId: string) => {
    console.log("Loading document", documentId);
    const document = await getDocument(documentId);
    set({ documents: [...get().documents, document] });
    return document;
  },
  loadDocuments: async () => {
    console.log("Loading documents");
    try {
      const documents = await fetchDocumentsAPI();
      const currentDocuments = get().documents;

      // Compare new documents with current documents
      documents.forEach((newDoc) => {
        const existingDoc = currentDocuments.find(
          (doc) => doc.id === newDoc.id
        );
        if (existingDoc && existingDoc.status !== newDoc.status) {
          if (newDoc.status === 2) {
            toast({
              title: "Document Processed",
              description: `Document "${newDoc.origin}" has been successfully processed.`,
              variant: "default",
            });
          } else if (newDoc.status === -1) {
            toast({
              title: "Processing Failed",
              description: `Processing failed for document "${newDoc.origin}".`,
              variant: "destructive",
            });
          }
        }
      });

      set({ documents });
    } catch (error) {
      console.error("Error loading documents:", error);
      toast({
        title: "Error",
        description: "Failed to load documents. Please try again later.",
        variant: "destructive",
      });
    }
  },
  startDocumentPolling: () => {
    const intervalId = setInterval(() => {
      get().loadDocuments();
    }, 30000); // 30 seconds
    set({ documentPollingInterval: intervalId });
  },
  stopDocumentPolling: () => {
    const intervalId = get().documentPollingInterval;
    if (intervalId) {
      clearInterval(intervalId);
      set({ documentPollingInterval: undefined });
    }
  },
});
