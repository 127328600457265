import { useState } from "react";
import { useStore } from "@/state/useState";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { fetchWithAuth, urlBuilder } from "@/lib/api/utils";

function ReadMoreText({
  text,
  maxLength = 300,
}: {
  text: string;
  maxLength?: number;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  if (text.length <= maxLength) {
    return <p className="text-sm">{text}</p>;
  }

  return (
    <div className="space-y-2">
      <p className="text-sm">
        {isExpanded ? text : `${text.substring(0, maxLength)}...`}
      </p>
      <Button
        variant="link"
        className="p-0 h-auto text-sm"
        onClick={toggleIsExpanded}
      >
        {isExpanded ? "Show less" : "Read more"}
      </Button>
    </div>
  );
}

export default function SearchResults() {
  const searchResults = useStore((state) => state.searchResults);
  const documents = useStore((state) => state.documents);
  const loadDocuments = useStore((state) => state.loadDocuments);
  const loadDocument = useStore((state) => state.loadDocument);

  const handleDownload = async (documentId: string) => {
    const doc = await loadDocument(documentId);
    if (doc.type === "sharepoint") {
      window.open(doc.download_url ?? "", "_blank");
      return;
    } else if (doc.type === "url") {
      window.open(doc.origin, "_blank");
      return;
    }

    await loadDocuments();
    console.log("Download", documentId);
    try {
      const response = await fetchWithAuth(
        urlBuilder(`/doc/download/${documentId}`)
      );
      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;

      // Get the document from our current documents state
      const documentData = documents.find((doc) => doc.id === documentId);
      const filename = documentData
        ? documentData.origin
        : `document-${documentId}`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading document:", error);
      alert("Error downloading document.");
    }
  };

  return (
    <ScrollArea className="h-[calc(100vh-300px)]">
      {searchResults.map((result) => (
        <Card key={result.id} className="mb-4">
          <CardContent className="pt-6">
            <ReadMoreText text={result.content} />
            <Button
              variant="link"
              onClick={() => handleDownload(result.document_id)}
              className="p-0 h-auto text-xs text-muted-foreground hover:underline"
            >
              Open document
            </Button>
          </CardContent>
        </Card>
      ))}
    </ScrollArea>
  );
}
